import { VIDEO_MEDIA_ID } from '../../../constants/components'
import Script from 'next/script'

export interface IPlayerAmp {
  videoId: string
  autoplay: boolean
}

export function PlayerAmp({ videoId, autoplay }: IPlayerAmp): JSX.Element {
  return (
    <>
      <Script
        async
        custom-element="amp-video-iframe"
        src="https://cdn.ampproject.org/v0/amp-video-iframe-latest.js"
      />

      <amp-video-iframe
        data-block-on-consent="_till_responded"
        referrerpolicy="unsafe-url"
        id={VIDEO_MEDIA_ID}
        src={`/player/${videoId}/?ampPage=true`}
        poster=""
        width="500"
        height="281"
        layout="responsive"
        autoplay={autoplay ? '' : undefined}
      ></amp-video-iframe>
    </>
  )
}
