import type { Player as ITF1Player } from '@etf1-interne/player'
import { useEffect, useRef } from 'react'
import { useViewport } from '../../../../hook/useViewport'
import { isServer } from '../../../../helpers/client'

export function useFullscreenOnRotate(player: { current: ITF1Player }, isEnabled: boolean) {
  const { isMobile } = useViewport()
  const isLandscape = useRef(!isServer() ? window.innerHeight < window.innerWidth : false)

  function handleOrientation() {
    const isNowLandscape = window.innerHeight < window.innerWidth
    const hasRotate = isLandscape.current !== isNowLandscape

    if (!hasRotate) return
    isMobile && isNowLandscape
      ? player.current?.fullscreenEnter()
      : player.current?.fullscreenExit()

    isLandscape.current = isNowLandscape
  }
  useEffect(
    function handleDeviceRotationEffect() {
      if (isMobile && isEnabled) {
        window?.addEventListener('resize', handleOrientation, { passive: true })
        return function cleanup() {
          window?.removeEventListener('resize', handleOrientation)
        }
      }
    },
    [isMobile],
  )
}
