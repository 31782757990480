import { mixins } from '../../../styles'

const INPUT_TOGGLE_ID = 'blocker-input-toggle'

export interface IBlockerLayout {
  id: string
}

export function BlockerLayout({ id }: IBlockerLayout): JSX.Element {
  const inputId = `${INPUT_TOGGLE_ID}-${id}`
  return (
    <>
      <div className="BlockerLayout flex items-center">
        <input
          id={inputId}
          className="BlockerLayout__Message_Toggle"
          type="checkbox"
          autoComplete="off"
        />
        <div className="BlockerLayout__Details">
          <div className="BlockerLayout__Title">Comment désactiver votre Adblocker</div>
          <ul>
            <li>
              Cliquez sur l&#39;icône de votre Adblocker installé dans votre navigateur. En général
              elle se trouve dans le coin supérieur droit de votre écran. Si vous utilisez plusieurs
              adblockers, veillez à bien tous les désactiver pour pouvoir accéder à votre vidéo.
            </li>
            <li>
              Suivez les instructions indiquées par votre Adblocker pour le désactiver. Vous devrez
              peut-être sélectionner une option dans un menu ou cliquer sur plusieurs boutons.
            </li>
            <li>Lorsque votre Adblocker est désactivé, actualisez votre page web.</li>
            <li>
              Remarque : Si vous utilisez le navigateur Firefox, assurez-vous de ne pas être en
              navigation privée.
            </li>
          </ul>
        </div>
        <div className="BlockerLayout__Message">
          <img
            className="BlockerLayout__Icon"
            src="/images/icons/adblock.svg"
            alt="adblock icone"
          />
          <div className="BlockerLayout__Title">Un bloqueur de publicité empêche la lecture.</div>
          <div className="BlockerLayout__Subtitle">
            Veuillez le désactiver et réactualiser la page pour démarrer la vidéo.
          </div>

          <label className="BlockerLayout__Button" htmlFor={inputId}>
            <span>Comment désactiver votre Adblocker</span>
          </label>
        </div>
      </div>

      <style jsx>{`
        .BlockerLayout {
          position: absolute;
          background-color: var(--color-darkestBlue);
          top: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
        }

        .BlockerLayout__Message {
          display: block;
        }
        .BlockerLayout__Details {
          display: none;
        }

        .BlockerLayout__Message,
        .BlockerLayout__Details {
          text-align: center;
          width: 100%;
          padding: 0 7%;
          color: var(--color-white);
          font-size: 11px;
          font-weight: 150;
          letter-spacing: 0.4px;
        }

        .BlockerLayout__Icon {
          display: inline-block;
          vertical-align: baseline;
          min-width: 40px;
          max-width: 40px;
          margin-bottom: 8px;
        }

        .BlockerLayout__Title {
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 8px;
        }

        .BlockerLayout__Button,
        .BlockerLayout__Message_Toggle {
          display: none;
        }

        .BlockerLayout__Details ul {
          padding: 0;
        }

        .BlockerLayout__Button {
          cursor: pointer;
          outline: none;
          border: none;
          text-decoration: none;

          display: none;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 8px;
          padding: 8px;
          border-radius: 8px;
          background: var(--color-white);

          color: var(--color-darkestBlue);
          font-style: normal;
          font-size: 15px;
          font-weight: 700;
          line-height: 141%;
        }

        @media ${mixins.mediaQuery.mobile} {
          .BlockerLayout__Message,
          .BlockerLayout__Details {
            font-size: 15px;
          }

          .BlockerLayout__Title {
            font-size: 18px;
          }

          .BlockerLayout__Icon {
            min-width: 60px;
            max-width: 60px;
          }
        }

        @media ${mixins.mediaQuery.tablet} {
          .BlockerLayout__Icon {
            min-width: 80px;
            max-width: 80px;
            margin-bottom: 16px;
          }

          .BlockerLayout__Title {
            font-size: 24px;
          }

          .BlockerLayout__Button {
            display: flex;
          }

          .BlockerLayout__Message_Toggle:checked ~ .BlockerLayout__Message {
            display: none;
          }

          .BlockerLayout__Message_Toggle:checked ~ .BlockerLayout__Details {
            display: block;
          }
        }

        @media ${mixins.mediaQuery.tabletPaysage} {
          .BlockerLayout__Message,
          .BlockerLayout__Details {
            font-size: 12px;
          }
        }
      `}</style>
    </>
  )
}
