import { useRouter } from 'next/router'
import { useAmp } from 'next/amp'

import { mixins, theme } from '../../../styles'
import { Button } from '../../atoms/Button'
import { PlaceholderLogo } from '../../atoms/Icon/TF1Info/PlaceholderLogo'
import { setupOriginUrlCookie } from '../../../helpers/cookies'
import { GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_LIVE } from '../../../constants/gigya'

export function PlayerBlock({ isLoading = false, wordings }): JSX.Element {
  const { asPath } = useRouter()
  const isAmp = useAmp()
  const text = wordings?.signInTitle

  const onClickOnLogin = () => {
    setupOriginUrlCookie(asPath)
    window.location.assign('/compte/connexion/')
  }

  return (
    <>
      <div className="PlayerBlock">
        {isLoading && !isAmp ? (
          <div className="PlayerBlock__Placeholder flex justify-center items-center">
            <PlaceholderLogo />
          </div>
        ) : (
          <div className="PlayerBlock__Content flex flex-column justify-center">
            <div className="PlayerBlock__Text">{text}</div>
            <div className="PlayerBlock__Buttons flex justify-center">
              <Button
                href={
                  isAmp
                    ? 'https://tf1infos.page.link/app'
                    : `/compte/inscription/?serviceType=${GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_LIVE}`
                }
                backgroundColor={theme.cssVars.yellowGolden}
                textColor={theme.cssVars.deepBlue}
              >
                {wordings?.signUpButton}
              </Button>
              <Button
                href={isAmp ? asPath.replace('/amp', '') : '#'}
                backgroundColor={theme.cssVars.lightGrayBlue}
                textColor={theme.cssVars.deepBlue}
                onClick={onClickOnLogin}
              >
                {wordings?.signInButton}
              </Button>
            </div>
          </div>
        )}
      </div>

      <style jsx>{`
        .PlayerBlock {
          position: relative;
          padding-top: 56.25%;
          width: 100%;
          background: ${theme.cssVars.darkestBlue};
          overflow: hidden;
        }

        .PlayerBlock__Content {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.2);
        }

        .PlayerBlock__Placeholder {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: ${theme.cssVars.deepBlue};
        }

        .PlayerBlock__Text {
          font-weight: 500;
          font-size: 16px;
          color: ${theme.cssVars.white};
          text-align: center;
          z-index: 1;
        }

        .PlayerBlock__Buttons {
          margin-top: 20px;
        }

        .PlayerBlock__Buttons :global(a) {
          margin: 10px;
          max-width: 200px;
          padding: 20px 5px;
          text-align: center;
        }

        @media ${mixins.mediaQuery.tablet} {
          .PlayerBlock {
            height: 300px;
            min-width: 500px;
          }
        }
      `}</style>
    </>
  )
}
