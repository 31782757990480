import { useContext, useEffect, useRef, useState } from 'react'
import { TAGCOMMANDER_SCRIPT_ID, TEADS_SCRIPT_ID } from '../constants/scripts'
import { useScript } from './useScript'
import { TagCommanderContext } from '../contexts/tagcommander'
import { GigyaContext } from '../contexts/GigyaAuth'

export function useBlockerDetector(): { isActive: boolean } {
  const [isBlockerActive, setIsBlockerActive] = useState(false)
  const { hasScriptError: hasTeadsScriptError } = useScript({
    id: TEADS_SCRIPT_ID,
  })
  const { isScriptLoaded: isTagCommanderScriptLoaded, hasScriptError: hasTagCommanderScriptError } =
    useScript({ id: TAGCOMMANDER_SCRIPT_ID })
  const { isTagCommanderReady } = useContext(TagCommanderContext)
  const { isGigyaReady, hasError: hasGigyaScriptError } = useContext(GigyaContext)
  const timeoutId = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (isTagCommanderScriptLoaded && isGigyaReady) {
      if (timeoutId.current) clearTimeout(timeoutId.current)
      // We wait 5 seconds to check if the TagCommander is ready
      timeoutId.current = setTimeout(() => {
        setIsBlockerActive(!isTagCommanderReady)
      }, 5000)
    }
  }, [isTagCommanderScriptLoaded, isGigyaReady, isTagCommanderReady])

  return {
    isActive:
      // Detect adblock if Teads / Gigya or TagCommander script has an error
      // Or if Gigya and TagCommander are loaded but TagCommander can't be used
      hasTeadsScriptError || isBlockerActive || hasGigyaScriptError || hasTagCommanderScriptError,
  }
}
