import { useState, useEffect, useRef } from 'react'

export enum Direction {
  Up = 'up',
  Down = 'down',
  Start = 'start',
}

export function useScrollDirection(): { scrollDirection: string } {
  const [scrollDirection, setScrollDirection] = useState(Direction.Start)
  const ticking = useRef(false)
  const lastScrollPosition = useRef(0)

  function updateScrollDir() {
    const scrollY = window.pageYOffset

    if (Math.abs(scrollY - lastScrollPosition.current) < 0) {
      ticking.current = false
      return
    }
    setScrollDirection(scrollY > lastScrollPosition.current ? Direction.Down : Direction.Up)
    lastScrollPosition.current = scrollY > 0 ? scrollY : 0
    ticking.current = false
  }

  const onScroll = () => {
    if (!ticking.current) {
      window.requestAnimationFrame(updateScrollDir)
      ticking.current = true
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return { scrollDirection }
}
